import cz from '~/locales/cz.json'
import en from '~/locales/en.json'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'cz',
  messages: {
    cz,
    en
  }
}))
